<template>
  <div>
    <form @submit.prevent='submit' class="border radius-1">
    <div class="row p-3 justify-content-center">
      <div class="col-6">
        <SelectField :field='categoryField' :selectOptions='categoryItems' :model.sync='chosenCategory'/>
      </div>
      <div class="col-6">
        <SelectField :field='childrenField' :selectOptions='childrenItems' :model.sync='chosenChild'/>
      </div>
      <button class="my-4 col-4 btn-success" type='submit'>{{$t('Add')}}</button>
    </div>
    <div class="card-desc text-center row mx-auto ">
      <div 
        v-for="(cat) in categoriesList"
        :key='cat.id'
        class="btn-pri-sm my-2 text-center"
        
      >
        {{htmlDecode(cat.name)}}
        <i class="fa fa-times px-3 pointer" @click='removeItem(cat.id)'/>
      </div>
    </div>
  </form>
</div>

</template>

<script>
import SelectField from '@/components/forms/fields/SelectField.vue';
import {helpers} from '../../../Helpers';

export default {
  data() {
    return {
      childrenItems: [],
      chosenCategory: {},
      chosenChild: {},
      categoriesList: [],
    }
  },
  props: ['formData', 'categoryItems'],
  mixins: [helpers],
  watch: {
    chosenCategory(newCat, oldCat) {
      console.log(newCat)
      if(newCat !== oldCat) {
        if (newCat.childs && newCat.childs.length > 0) {
          this.childrenItems = [...newCat.childs]
        }
      }
    },
    // categoriesList(newList, oldList) {
    //   if(newList !== oldList) {
    //     this.initiateCategoriesList()
    //   }
    // }
  },
  mounted() {
    this.initiateCategoriesList();
  },
  components: {
    SelectField
  },
  methods: {
    submit() {
      let chosenId = Object.keys(this.chosenChild).length > 0 ? this.chosenChild : this.chosenCategory;
      if(this.formData.categories_ids.includes(chosenId.id) == false  && this.formData.categories_ids.includes(chosenId.parent_id) == false && chosenId.id) {
        this.formData.categories_ids.push(chosenId.id);
        this.categoriesList.push(chosenId);
      }
      console.log("🚀 ~ file: CategoryChooseForm.vue ~ line 72 ~ initiateCategoriesList ~ this.formData.categories_ids", this.formData.categories_ids)
      this.chosenCategory = this.chosenChild = {};
      this.childrenItems = [];
    },
    initiateCategoriesList() {
      this.categoriesList = [];
      this.formData.categories_ids.forEach(id => {
      console.log("🚀 ~ file: CategoryChooseForm.vue ~ line 79 ~ initiateCategoriesList ~ id", id)
      this.categoryItems.forEach(el => {
        if(el.id == id) {
          this.categoriesList.push(el)
        }
        if(el.childs && el.childs.length > 0) {
          let childId = el.childs.find(child => child.id == id);
          if(childId) this.categoriesList.push(childId)

        }
      });
    });
    },
    removeItem(itemId) {
      let index = this.formData.categories_ids.indexOf(itemId);
      this.formData.categories_ids.splice(index, 1);
      this.initiateCategoriesList();
    }
  },
  computed: {
    categoryField() {
      return { label: this.$t('categories'), name: 'categories', id: 'categories'}
    },
    childrenField() {
      return { label: this.$t('childCategories'), name: 'childCategories', id: 'childCategories'}
    },
  }
}
</script>