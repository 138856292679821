<template>
  <div class="container px-0">
    <div class="row justify-content-center">
      <div class="col-12 px-0" v-if="edit != true">
        <div class="row" v-if="
          !profileLoading && profileBusiness.data && items && items.length > 0
        ">
          <div class="col-12 px-0 col-xl-6 my-4" :class="flex_ar" v-for="(bus, index) in items" :key="index">
            <div class="row card-desc mx-xl-2" :class="flex_ar">
              <div class="col-12 px-0 col-sm-3 align-self-center text-center pointer" @click="goBusinss(bus.id)">
                <div>
                  <!-- <div class="item-address my-2">
                      <button class="btn-pri text-ellipsis"> {{'berlin'}} </button>
                    </div> -->
                  <img class="proBus-image" :src="
                    bus.media && bus.media[0]
                      ? bus.media[0].url
                      : placeHolderImg
                  " />
                </div>
              </div>
              <div class="col-12 px-0 col-sm-9">
                <div class="row" :class="t_ar">
                  <div class="col-12 px-0 align-items-center">
                    <span class="res-name">{{ bus.name }}</span>
                    <!-- <button class="btn-icon-trans  dropdown" id="businessSettings" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" type='button' :class="f_ar_reverse">
                      <i class=" fa fa-ellipsis-v text-dark" ></i>
                    </button>
                    <div class="dropdown-menu " :class="$i18n.locale == 'ar' ? 'dropdown-menu-left' : 'dropdown-menu-right'" aria-labelledby="businessSettings">
                      <div class="dropdown-item pointer" @click='editObj = bus;edit = true; '>edit</div>
                      <div class="dropdown-item pointer" >delete</div>
                      <div class="dropdown-item pointer" ></div>
                    </div> -->
                  </div>
                </div>
                <div class="row mt-3" :class="[t_ar]">
                  <!-- <div class="col-4">
                    <Stars :stars=5 />
                  </div>
                  <span>{{222}}</span> -->
                  <div class="col-12 px-0">
                    <span class="del-badge-pri">
                      <i class="fa fa-check-circle"></i> {{ $t("delivery") }} </span>
                    <span class="del-badge-pri">
                      <i class="fa fa-check-circle"></i> {{ $t("takeAway") }} </span>
                  </div>
                </div>
                <div class="row border-top mt-3 p-xl-2" :class="t_ar">
                  <div class="col-12 px-0" v-html="bus.description"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-12 col-xl-6  my-4">
            <div class="row card-desc mx-xl-2 py-5">
              <div class="col-12 text-center">
                <button class="btn-icon-trans border m-4">
                  <i class="fa fa-plus"/>
                </button>
                <div class="opt-label">{{$t('addNewBusiness')}}</div>
              </div>
            </div>
          </div> -->
          <div class="col-12 mt-5 px-0">
            <Pagination v-if="items.length > 0" :currentPage="page" :showPage="showPage" :lastPage="lastPage">
            </Pagination>
          </div>
        </div>
        <NoPlaces v-else :msg="$t('NoBuinessOwnedBythisUser')" :imgWith="true"
          :img="require('../../assets/images/empty.png')" />
      </div>
      <AddNewBusiness v-if="edit == true" :editObj="editObj" />
    </div>
  </div>
</template>

<script>
import Stars from "@/components/misc/Stars.vue";
import Pagination from "@/components/misc/Pagination.vue";
import NoPlaces from "@/components/misc/NoPlaces.vue";
import AddNewBusiness from "@/components/forms/AddNewBusiness.vue";
import { mapGetters, mapActions } from "vuex";
import cookies from "vue-cookies";

export default {
  components: {
    Stars,
    Pagination,
    NoPlaces,
    AddNewBusiness,
  },

  data() {
    return {
      page: 1,
      placeHolderImg: require("../../assets/images/logo-icon.png"),
      edit: false,
      editObj: {},
    };
  },
  mounted() {
    this.getProfileBusiness({
      api_token: cookies.get("user_token"),
      page: this.page,
    });
  },
  methods: {
    ...mapActions(["getProfileBusiness"]),
    showPage(page) {
      if (page != this.page) {
        this.page = page;
        this.getProfileBusiness({
          api_token: cookies.get("user_token"),
          page: this.page,
        });
      }
    },
    goBusinss(id, tab = 1) {
      this.$router.push({
        name: "BusinessProfile",
        params: {
          locale: this.$i18n.locale,
          id,
        },
      });
    },
  },
  computed: {
    ...mapGetters(["width", "profileBusiness", "profileLoading"]),
    lastPage() {
      return this.profileBusiness.last_page;
    },
    items() {
      return this.profileBusiness.data;
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    t_ar_reverse() {
      return this.$i18n.locale == "ar" ? "text-left" : "text-right";
    },
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "";
    },
  },
};
</script>