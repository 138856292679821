<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-11">
        <div class="row" :class="flex_ar">
          <div class="col-12 col-md-9">
            <div class="opt-name my-5" :class="t_ar">
              {{ $t("mySavedCards") }}
            </div>
            <div
              class="card-desc row my-4"
              :class="flex_ar"
              v-for="(card, ind) in cards"
              :key="ind"
            >
              <div class="col-3 col-md-1">
                <img src="../../assets/images/miscellaneous/visa.png" />
              </div>
              <div class="col-9" :class="t_ar">
                <div class="opt-name">{{ card.name }}</div>
                <div class="opt-label">
                  {{ $t("EndWith") }} ****{{ card.end }}
                </div>
              </div>
              <div class="col-12 col-md-1 align-self-center text-center">
                <button class="btn-icon-trans shadow-eff">
                  <i class="fa fa-pencil" />
                </button>
              </div>
            </div>
            <div
              @click="openModal"
              class="card-desc row my-4 pointer"
              :class="flex_ar"
            >
              <div class="col-3 col-md-1">
                <button class="btn-icon-trans border">
                  <i class="fa fa-plus" />
                </button>
              </div>
              <div class="col-9 col-md-11 align-self-center" :class="t_ar">
                <span class="opt-label">{{ $t("addNewCard") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade pb-9"
      :class="width == 'sm' ? 'pr-3 ' : ''"
      tabindex="-1"
      id="cardModal"
    >
      <div
        class="mx-2 modal-dialog"
        :class="width == 'sm' ? 'w-100' : ['modal-xl', 'mx-auto']"
      >
        <div class="modal-content filter-contain">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <CardForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardForm from "@/components/forms/CardForm.vue";

export default {
  components: {
    CardForm,
  },
  data() {
    return {
      cards: [
        { name: "visa", end: "3345" },
        { name: "visa", end: "3345" },
        { name: "visa", end: "3345" },
        { name: "visa", end: "3345" },
      ],
    };
  },
  computed: {
    ...mapGetters(["width"]),
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "";
    },
  },
  methods: {
    openModal() {
      $("#cardModal").modal("show");
    },
  },
};
</script>