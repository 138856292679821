var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mx-3 mt-8"},[_c('div',{staticClass:"col-12"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},_vm._l((_vm.formFields),function(row,index){return _c('div',{key:index,staticClass:"form-group row my-3",class:[_vm.flex_ar]},_vm._l((row.cols),function(field){return _c('div',{key:field.label,staticClass:"px-4 col my-4",class:[
          _vm.flex_ar,
          field.name == 'phone' ? 'input-group' : '',
          field.class ]},[_c('label',{staticClass:"input-label",class:[_vm.f_ar],attrs:{"for":field.id}},[_vm._v(_vm._s(field.label))]),(field.type == 'file')?_c('span',{staticClass:"file-field-name"},[_vm._v(_vm._s(_vm.fileName))]):_vm._e(),(field.type == 'textarea')?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData[field.name]),expression:"formData[field.name]"}],staticClass:"textarea-grey",attrs:{"rows":"5"},domProps:{"value":(_vm.formData[field.name])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, field.name, $event.target.value)}}}):(field.type == 'dropdown')?_c('select',{staticClass:"text-input-grey"},[_c('option',{attrs:{"value":"0","selected":""}},[_vm._v(_vm._s(_vm.$t("none")))]),_vm._l((field.options),function(opt,i){return _c('option',{key:i,domProps:{"value":field.name}},[_vm._v(" "+_vm._s(opt)+" ")])})],2):((field.type || 'text')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData[field.name]),expression:"formData[field.name]"}],staticClass:"text-input-grey px-3",class:[
            _vm.t_ar,
            field.type == 'file' ? 'file-field' : '',
            !field.disabled ? 'border' : '',
            field.type == 'checkbox'
              ? _vm.$i18n.locale == 'ar'
                ? 'custom-control-input-grey-ar'
                : 'custom-control-input-grey'
              : '' ],attrs:{"placeholder":field.label,"disabled":field.disabled,"id":field.id,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.formData[field.name])?_vm._i(_vm.formData[field.name],null)>-1:(_vm.formData[field.name])},on:{"change":[function($event){var $$a=_vm.formData[field.name],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formData, field.name, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formData, field.name, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formData, field.name, $$c)}},_vm.changeFile]}}):((field.type || 'text')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData[field.name]),expression:"formData[field.name]"}],staticClass:"text-input-grey px-3",class:[
            _vm.t_ar,
            field.type == 'file' ? 'file-field' : '',
            !field.disabled ? 'border' : '',
            field.type == 'checkbox'
              ? _vm.$i18n.locale == 'ar'
                ? 'custom-control-input-grey-ar'
                : 'custom-control-input-grey'
              : '' ],attrs:{"placeholder":field.label,"disabled":field.disabled,"id":field.id,"type":"radio"},domProps:{"checked":_vm._q(_vm.formData[field.name],null)},on:{"change":[function($event){return _vm.$set(_vm.formData, field.name, null)},_vm.changeFile]}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData[field.name]),expression:"formData[field.name]"}],staticClass:"text-input-grey px-3",class:[
            _vm.t_ar,
            field.type == 'file' ? 'file-field' : '',
            !field.disabled ? 'border' : '',
            field.type == 'checkbox'
              ? _vm.$i18n.locale == 'ar'
                ? 'custom-control-input-grey-ar'
                : 'custom-control-input-grey'
              : '' ],attrs:{"placeholder":field.label,"disabled":field.disabled,"id":field.id,"type":field.type || 'text'},domProps:{"value":(_vm.formData[field.name])},on:{"change":_vm.changeFile,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, field.name, $event.target.value)}}}),(field.error || field.vaild)?_c('small',{class:field.valid ? 'text-success' : 'text-error'},[_vm._v(" "+_vm._s(field.valid || field.error)+" ")]):_vm._e()])}),0)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }