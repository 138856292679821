<template>
  <div>
    <form @submit.prevent='submit' class="border radius-1">
    <div class="row p-3 justify-content-center">
      <div class="col-12">
        <SelectField :field='tagField' :selectOptions='tagsItems' :model.sync='chosenTag'/>
      </div>
      <button class="my-4 col-4 btn-success" type='submit'>Add</button>
    </div>
    <div class="card-desc text-center row mx-auto">
      <div 
        v-for="(tag) in tagsList"
        :key='tag.id'
        class="btn-pri-sm my-2 text-center"
      >
        {{htmlDecode(tag.name)}}
        <i class="fa fa-times px-3 pointer" @click='removeItem(tag.id)'/>
      </div>
    </div>
  </form>
</div>

</template>

<script>
import SelectField from '@/components/forms/fields/SelectField.vue';
import {helpers} from '../../../Helpers';

export default {
  data() {
    return {
      chosenTag: {},
      tagsList: [],
    }
  },
  props: ['formData', 'tagsItems'],
  mixins: [helpers],
  watch: {
    // tagsList(newList, oldList) {
    //   if(newList !== oldList) {
    //     this.initiateTagsList()
    //   }
    // }
  },
  mounted() {
    this.initiateTagsList();
  },
  components: {
    SelectField
  },
  methods: {
    submit() {
      if(this.formData.tags.includes(this.chosenTag.id) == false  && this.chosenTag.id) {
        this.formData.tags.push(this.chosenTag.id);
        this.tagsList.push(this.chosenTag);
      }
      console.log("🚀 ~ file: CategoryChooseForm.vue ~ line 72 ~ initiateTagsList ~ this.formData.categories_ids", this.formData.tags)
      this.chosenTag = {};
    },
    initiateTagsList() {
      this.tagsList = [];
      if(this.formData.tags && this.formData.tags.length > 0) {
        this.formData.tags.map(id => {
          var element = this.tagsItems.find(el => el.id == id);
          this.tagsList.push(element)
        });
      }
    },
    removeItem(itemId) {
      let index = this.formData.tags.indexOf(itemId);
      this.formData.tags.splice(index, 1);
      this.initiateTagsList();
    }
  },
  computed: {
    tagField() {
      return { label: this.$t('tags'), name: 'tags', id: 'tags'}
    },
  }
}
</script>