<template>
  <div class="row">
    <div class="col-12" v-if='items && items.length > 0'>
      <!-- <div class="main-card my-5 px-2 py-4 row justify-content-around">
        <div class="row">
            <div class="col">
              <button 
                class='btn-dropdown-pri-small dropdown-toggle'
                id="sortByDropdown" 
                data-toggle="dropdown" 
                aria-haspopup="true" 
                aria-expanded="false"
                :disabled='selectedItems.length == 0 ? true : false'
              > 
                {{$t('Status')}}
              </button>
              <div class="dropdown-menu" aria-labelledby="sortByDropdown">
                <a class="dropdown-item" href="#">Action</a>
                
              </div>
              <button 
                :disabled='selectedItems.length == 0 ? true : false' 
                class="btn-error-small"
              >{{$t('delete')}} </button>
            </div>
          </div>
        <div 
          class="data-label"
          :class="header.class"
          v-for='(header, i) in headers'
          :key='i'
        >
          {{header.text}}
        </div>
      </div> -->
      <div class="row">
        <div class="col-12">
          <div 
            class="row border-bottom my-3 py-2"
            v-for='(item, i) in items'
            :key='i'
          >
            <div class="col-2 align-self-center text-center">
              <!-- {{selectedItems}} -->
              <!-- <input type="checkbox" class="mx-4" v-model='selectedItems' :value='item.id' id="customCheck1"> -->
              <img class="radius-1" width='90px' :src='item.media && item.media[0] ? item.media[0].url  : placeHolder' />
            </div>
            <div class="col-3 align-self-center">
                <div class="opt-name text-underline mb-1 ">{{item.name}}</div>
            </div>
            <div class="col-1 align-self-center">
              <div class="subheader-home mb-1 text-ellipsis">{{item.price}}</div>
            </div>
            <div class="col-2 align-self-center">
              <div class="subheader-home ">{{item.categories ? item.categories[0].name : '' }}</div>
            </div>
            <div class="col-2 align-self-center">
              <div class="subheader-home ">{{item.business.name }}</div>
            </div>
            <div class="col-1 align-self-center">
              <div class="subheader-home ">{{item.quantity == -1 ? $t('unlimited') : item.quantity }}</div>
            </div>
            <!-- <div class="col-1 align-self-center">
              <button class="btn-icon-trans " type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-ellipsis-v text-dark" /> </button>
              <div class="dropdown-menu">
                <li class="dropdown-item mouse" @click='editItem(item)'>{{$t('view')}}</li>
                <li class="dropdown-item mouse" @click='editItem(item)'>{{$t('edit')}}</li>
                <li class="dropdown-item mouse" @click='deleteItem(item)'>{{$t('delete')}}</li>
              </div>
            </div> -->
            
            <!-- <div class="col-1 align-self-center">
              <button class="btn-icon-trans shadow-eff" :disabled='loading' @click='editItem(item)'>
                <i class="fa fa-pencil"/>
              </button>
              <button class="btn-icon-trans shadow-eff" :disabled='loading' @click='deleteItem(item)'>
                <i class="fa fa-trash text-error"/>
              </button> -->
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    data() {
      return {
        placeHolder: require('../../../assets/images/logo/logo-icon.png'),
        selectedItems: [],
      }
    },
    props: ['items', 'editItem', 'deleteItem'],
    computed: {
      ...mapGetters(['loading']),
      headers(){
        return [
          { text: '', class: '' },
          { text: this.$t('ProductName'), class: 'col-2' },
          { text: this.$t('Price'), class: 'col-2' },
          { text: this.$t('Category'), class: 'col-2' },
          { text: this.$t('Business'), class: 'col-2' },
          { text: this.$t('Quantity'), class: 'col-2' },
          // { text: this.$t('Actions'), class: 'col-1' },
        ]
      } 
    },
    mounted() {
      console.log(this.items)
    },
    methods: {
      
    }
  }
</script>