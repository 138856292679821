<template>
  <div class="container">
    <div class="row">
      <div class="col-12" v-if="item && item.business && item.business.media.length > 0">
        <div class="row">
          <div class="col-12 col-md-4 col-lg-3">
            <div class="order-image text-center">
              <img :src="item.business.media[0].url" />
            </div>
          </div>
          <div class="col-12 col-md-8 p-2">
            <div class="opt-name mb-2">{{ item.business.name }}</div>
            <div class="opt-label">#{{ item.id }}</div>
            <div class="opt-label mt-2"> {{ item.on_date }} ( {{ item.time ? item.time.time : item.from }} ) </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="row">
          <div class="col-12" :class="t_ar">
            <div class="opt-name mb-2">{{ $t("clientOptions") }}</div>
            <div class="opt-label mb-2" v-if="item.time"> {{ $t("Service") }} <span class="mx-2"
                :class="f_ar_reverse">{{ item.time.name }}</span>
            </div>
            <div class="opt-label"> {{ $t("Hall") }} <span class="mx-2" :class="f_ar_reverse">{{ item.hall.name
            }}</span>
            </div>
            <div class="opt-label"> {{ $t("Date") }} <span class="mx-2" :class="f_ar_reverse">{{ item.on_date }}</span>
            </div>
            <div class="opt-label"> {{ $t("TimeFrame") }} <span class="mx-2" :class="f_ar_reverse"> {{ item.time ?
                item.time.time : item.from
            }} <i class="fa fa-arrows-h mx-1 text-muted" /> {{ item.time ?
    item.time.to_time : item.to
}} </span>
            </div>
            <div class="opt-label" v-if="item.time && item.time.enter_from && item.enter_to"> {{ $t("AttendTime") }}
              <span class="mx-2" :class="f_ar_reverse"> {{ item.time.enter_from }} <i
                  class="fa fa-arrows-h mx-1 text-muted" /> {{ item.time.enter_to }} </span>
            </div>
          </div>
          <div class="col-12 my-3" :class="t_ar">
            <div class="opt-name mb-2">{{ $t("ReservationStatus") }}</div>
            <div class="opt-label"> {{ $t("AttendersNo") }} <span class="mx-2" :class="f_ar_reverse">{{ item.persons
            }}</span>
            </div>
            <div class="opt-label mb-2" v-if="item.total_amount && item.payments.length > 0"> {{ $t("paymentMethod") }}
              <span class="mx-2" :class="f_ar_reverse">{{ payText(item.payments[0].method) }}</span>
            </div>
            <div class="opt-label" v-if="item.total_amount"> {{ $t("TotalAmount") }} <span class="mx-2"
                :class="f_ar_reverse">{{ item.total_amount }}</span>
            </div>
            <div class="opt-label" v-if="item.total_amount && item.payments.length > 0"> {{ $t("paymentStatus") }} <span
                class="mx-2" :class="f_ar_reverse">{{ statusText(item.payments[0].status) }}</span>
            </div>
          </div>
          <div class="col-12 my-3" :class="t_ar" v-if="item.orders.length > 0">
            <div class="opt-name mb-2">{{ $t("ConnectedOrders") }}</div>
            <div class="my-3" v-for="order in item.orders" :key="order.id">
              <div class="opt-label"> {{ $t("orderNumber") }} <span class="mx-2" :class="f_ar_reverse">{{
                  item.orders[0].id
              }}</span>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { helpers } from "../../Helpers";

export default {
  mixin: [helpers],
  props: ["item"],
  methods: {
    ...mapActions(["attachOrderToReservation"]),
    payText(text) {
      if (text == 1) {
        return "Cash On Delivery";
      } else if (text == 3) {
        return "Payment On Delivery";
      } else if (text == 2) {
        return "Paypal";
      }
    },
    statusText(text) {
      if (text == 1) {
        return "Paid";
      } else if (text == -1) {
        return "Didnt Pay";
      } else if (text == 2) {
        return "Waiting";
      }
    },
    // goPay() {
    //   let secretKey = this.createReserveKey(res.data.data.created_at, res.data.data.id, this.user.id, this.business.id);
    //   if(this.item.total_amount) {
    //     this.$router.push({
    //       name: "Payment",
    //       params: {
    //         total: this.selectedData.total_paid,
    //         client_id: this.business.paypal_client_id,
    //         client_secret: this.business.paypal_secret,
    //         reservation_id: res.data.data.id,
    //         secret_word: secretKey,
    //       },
    //     });
    //   }
    // },
    linkOrder() { },
  },
  computed: {
    ...mapGetters(["user", "loading", "modalLoading"]),
    flex_ar_reverse() {
      return this.$i18n.locale == "ar" ? "" : "flex-row-reverse";
    },
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    t_ar_reverse() {
      return this.$i18n.locale == "ar" ? "text-left" : "text-right";
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
  },
};
</script>