<template>
  <div class="container px-0">
    <div class="row ">
      <div class="col-12 px-0" v-if="
        !profileLoading &&
        myProducts &&
        myProducts.results &&
        myProducts.results.data.length > 0
      ">
        <!-- <div class='row justify-content-between' :class="flex_ar">
            <div class="col" :class="[t_ar, flex_ar]">
              <button class="btn-sec">{{$t('MultiEdit')}}</button>
              <button class="btn-sec">{{$t('CopyProducts')}}</button>
              <button class="btn-pri">
                <i class="fa fa-plus" />  
                {{$t('Create')}}
              </button>
            </div>
            <div class="col" :class="[t_ar_reverse, flex_ar]">
              <button class='btn-dropdown-sec dropdown-toggle' id="sortByDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> {{$t('SortBy')}}</button>
              <div class="dropdown-menu" aria-labelledby="sortByDropdown">
                <a class="dropdown-item" href="#">Action</a>
                
              </div>
              <button class='btn-dropdown-sec dropdown-toggle' id="viewNo" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> {{$t('ViewNo')}}</button>
              <div class="dropdown-menu" aria-labelledby="viewNo">
                <a class="dropdown-item" href="#">Action</a>
                
              </div>
              <button class='btn-pri '> {{$t('Advanced')}}</button>
            </div>
          </div> -->
        <ProductsTable v-if="
          !profileLoading &&
          myProducts &&
          myProducts.results &&
          myProducts.results.data.length > 0
        " :items="myProducts.results.data" :editItem="editItem" :deleteItem="deleteItem" />
        <NoPlaces v-else />
        <div class="col-12 px-0 mt-5">
          <Pagination v-if="
            myProducts &&
            myProducts.results &&
            myProducts.results.data.length > 0
          " :currentPage="page" :showPage="showPage" :lastPage="lastPage"></Pagination>
        </div>
      </div>
      <NoPlaces v-else :msg="$t('NoProductsFound')" :imgWith="true" :img="require('../../assets/images/empty.png')" />
    </div>
    <!-- <AddNewProduct v-if='edit == true' :item='editObj' /> -->
    <div class="modal fade pb-9" :class="width == 'sm' ? 'pr-3 ' : ''" tabindex="-1" id="productsFilterModal">
      <div class="mx-2 modal-dialog" :class="width == 'sm' ? 'w-100' : ['modal-xl', 'mx-auto']">
        <div class="modal-content filter-contain">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddNewProduct from "@/components/forms/AddNewProduct.vue";
import ProductsTable from "@/components/profile/productELs/ProductsTable.vue";
import Pagination from "@/components/misc/Pagination.vue";
import NoPlaces from "@/components/misc/NoPlaces.vue";
import { mapActions, mapGetters } from "vuex";
import cookies from "vue-cookies";

export default {
  components: {
    AddNewProduct,
    ProductsTable,
    Pagination,
    NoPlaces,
  },
  data() {
    return {
      page: 1,
      edit: false,
      editObj: false,
    };
  },

  mounted() {
    this.getMyProducts({
      api_token: cookies.get("user_token"),
      lang: this.$i18n.locale,
      page: this.page,
    });
  },
  methods: {
    ...mapActions([
      "getMyProducts",
      "deleteProduct",
      "updateProduct",
      "updateProductImage",
      "createProduct",
    ]),
    openModal() {
      $("#productsFilterModal").modal("show");
    },
    closeModal() {
      $("#productsFilterModal").modal("hide");
    },
    editItem(item) {
      this.editObj = item;
      this.edit = true;
    },
    deleteItem(item) {
      console.log("delete");
    },
    showPage(page) {
      if (page != this.page) {
        this.page = page;
        this.getMyProducts({
          api_token: cookies.get("user_token"),
          lang: this.$i18n.locale,
          page: this.page,
        });
      }
    },
  },

  computed: {
    ...mapGetters(["width", "myProducts", "loading", "profileLoading"]),
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    t_ar_reverse() {
      return this.$i18n.locale == "ar" ? "text-left" : "text-right";
    },
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "";
    },
    lastPage() {
      return this.myProducts.results.last_page;
    },
  },
};
</script>