<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row justify-content-center">
          <div class="col-12 card-QR text-center">
            <VueQrcode class="qrcode" :scale="8" :value="encrypt()" />
            <div class="modal-header-custom mt-5">
              {{ $t("yourQR") }}
            </div>
            <div class="opt-label my-3">
              {{ $t("dontShare") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from "md5";
import cookies from "vue-cookies";
import VueQrcode from "vue-qrcode";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    VueQrcode,
  },
  props: ["userAddresses"],
  mounted() {
    if (this.userAddresses && this.userAddresses.length > 0) {
      console.log(
        "🚀 ~ file: ProfileQR.vue ~ line 34 ~ mounted ~ this.userAddresses",
        this.userAddresses
      );
    }
    this.createPrivateKey(cookies.get("user_token")).then((res) => {
      this.encrypt();
    });
  },
  methods: {
    ...mapActions(["createPrivateKey"]),
    encrypt() {
      let formatedData = {
        user_id: this.user.id,
        title: this.user.title,
        // name:
      };
      let key = md5(JSON.stringify(formatedData) + "|" + this.privateKey);
      return key;
    },
  },
  computed: {
    ...mapGetters(["privateKey", "user"]),
  },
};
</script>

userId = json['user_id'];
    title = json['title'];
    country = json['country']; 
    area = json['area'];
    address = json['address'];
    latitude = json['lattitude'];
    longitude = json['longitude'];
    phoneCode = json['phone_code'];
//////////////////////////////////////////////////////////////////////////
    postalCode = json['postal_code'].toString();
    houseNumber = json['apartment_no'];
    streetName = json['street_name'];
    addressNotes = json['notes'];
//////////////////////////////////////////////////////////////////////////
    phone = json['phone'];
    createdAt = json['created_at'];
    updatedAt = json['updated_at'];