<template>
  <div class="row p-md-4">
    <div class="col-12">
      <form  class="my-4 " >
        <div 
          class="form-group row"
          :class="flex_ar"
          v-for='(row, ind) in formFields'
          :key='ind'
        >
          <div 
            v-for='(col, index) in row.cols'
            :key='index'
            :class="col.class"  
          >
            <label class="input-label required" :class="f_ar">{{col.label}}</label>
            <input 
              type='text'
              class="text-input-grey px-3"
              :class="t_ar"
              v-model='card[col.name]'
              :placeholder="col.label"
              required
            />
          </div>
        </div>
        <div >
          <button class="btn-pri w-100 mt-4">
            {{$t('addCard')}}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      card: {},
      formFields: [
        {
          cols: [
            { name: 'number', label: this.$t('CreditCardNumber'), class: 'col-12' }
          ]
        },
        {
          cols: [
            { name: 'CVV', label: this.$t('CVV'), class: 'col-12 col-md-6'  },
            { name: 'expire', label: this.$t('ExpireDate'), class: 'col-12 col-md-6'  }
          ]
        },
        {
          cols: [
            { name: 'name', label: this.$t('CardName'), class: 'col-12'  }
          ]
        },

      ]
    }
  },
  computed: {
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right': 'f-left';
    },
    f_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'f-left': 'f-right';
    },
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right': 'text-left';
    },
    flex_ar() {
      return this.$i18n.locale == 'ar' ? 'flex-row-reverse': '';
    },
  }
}
</script>