<template>
  <form @submit.prevent="formSubmit(formData)" class="my-4 " >
    <div  
      v-for='(row, index) in formFields'
      :key=index
      class="form-group row my-3"
      :class="flex_ar" 
    >        
      <div 
        v-for='field in row.cols'
        :key='field.label'
        class="col-12 col-lg-6 px-4" 
        :class="[flex_ar, ]"
      >
        <label class="input-label" :class="[f_ar, field.required]">{{field.label}}</label>
        <input 
          :type="field.type || 'text'"
          class="text-input-grey px-3"
          :class="[t_ar, field.class]"
          v-model='formData[field.name]'
          :placeholder="field.label"
          :disabled='field.disabled'
          :id='field.id || field.name'
          :maxlength="field.name == 'postal_code' ? 5 : ''"
          :required='field.required ? true : false'
          @change='checkField(field)'
        />
        <small :class="[f_ar, (field.valid ? 'text-success' : 'text-error')]" v-if='field.error || field.valid'>
          {{field.valid || field.error}}
        </small>
      </div>
    </div>
    <div class="form-group text-center mt-5 my-4" >
      <button class="btn-success w-50" :disabled='loading' :class="loading ? 'btn-secondary' : ''">
        {{$t('save')}}
      </button>
    </div>
  </form>  
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  data() {
    return {
      formData: {},
    }
  },
  watch: {
    initialData: function(newData, oldData) {
      if(newData !== oldData) {
        this.formData = {...newData};
      }
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions(['getGeolocation']),
    ...mapMutations(['showMsg']),
    checkField(field) {
      console.log(this.formData)
      if( field.name == 'postal_code' ) {
        if(this.formData.postal_code.length != 0 && this.formData.postal_code.length > 3) {
          this.getGeolocation(this.formData.postal_code)
            .then(res => {
              field.valid = res.data.results[0].formatted_address;
              this.formData.latitude = this.geolocation.lat;
              this.formData.longitude = this.geolocation.lng;
              this.$forceUpdate();
            }).catch(err => {
              document.getElementById('postal_code').focus()
              field.error = this.$t('PostalCodeNotValid');
              new Error('Postal Code Not Valid')
              field.valid = false;
              this.$forceUpdate();
            });
        }else {
          document.getElementById('postal_code').focus()
          field.error = this.$t('PostalCodeNotValid');
          new Error('Postal Code Not Valid')
          field.valid = false;
          this.$forceUpdate ();

        }
      }
    },
  },
  props: ['formFields', 'formSubmit', 'initialData'],
  computed: {
    ...mapGetters(['loading', 'geolocation']),
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right': 'f-left';
    },
    f_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'f-left': 'f-right';
    },
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right': 'text-left';
    },
    flex_ar() {
      return this.$i18n.locale == 'ar' ? 'flex-row-reverse': '';
    },
  }
}
</script>