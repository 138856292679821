<template>
  <div>
    <label class="input-label" :for='field.id' :class="[f_ar, ]">{{field.label}}</label>
    <span v-if="field.type == 'file'" class="file-field-name">{{fileName}}</span>
    <input 
      v-if='field.name != "phone"'
      :type="field.type || 'text'"
      class="text-input-grey  px-3"
      :class="[t_ar, field.class, !field.disabled ? 'border' : '']"
      v-model='valueProp'
      :placeholder="field.label"
      :disabled='field.disabled'
      :id='field.id'
      @change='changeFile'
    />
    <input 
      v-else
      :type="field.type || 'text'"
      class="text-input-grey px-3"
      :class="[t_ar, field.class, !field.disabled ? 'border' : '',]"
      v-model='valueProp'
      :placeholder="field.label"
      :disabled='field.disabled'
      :id='field.id'
      v-mask='field.mask'
      :masked='true'
      @blur='validatePhone(field)'

    />
    <small 
      v-if='(field.error || field.vaild)' 
      :class="field.valid ? 'text-success' : 'text-error'"
    >
      {{field.valid || field.error}}
    </small>
  </div>

</template>

<script>
import { mapActions, mapGetters} from 'vuex';
  export default {
    data() {
      return {
        fileName: '',
        data: null,
      }
    },
    props: ['field', 'model'],
    watch: {
      // valueProp: function (newMod, oldMod) {
      //   console.log("🚀 ~ file: TextField.vue ~ line 55 ~ newMod", newMod)
      //   if(newMod !== oldMod) {
      //     this.data = newMod;
      //   }
      // },
      // data: function (newD , oldD) {
      //   if(newD !== oldD) {
      //     console.log("🚀 ~ file: TextField.vue ~ line 60 ~ newD", newD)
      //     this.updateValue(this.data)
      //    }
      // }
    },
    // mounted() {
    //   this.data = this.model;
    // },
    methods: {
      ...mapActions(['checkPhone']),
      changeFile() {
        if(this.field.type == 'file' && document.getElementById(this.field.id).files[0] ) {
          this.fileName = document.getElementById(this.field.id).files[0].name;
        }else console.log('nothing')
      },
      validatePhone(col) {
        if (!empty(this.model)) {
          col.loading = true;
          this.$forceUpdate();
          this.checkPhone(this.model)
            .then(res => {
              if (res.data.data == true) {
                col.valid = this.$t("VaildNumber");
                this.$forceUpdate();
              } else {
                $("#phone").select();
                col.error = this.$t("notVaildNumber");
                col.valid = null;
                this.$forceUpdate();
              }
            }).catch(err => {
              $('#phone').select();
              col.error = this.$t("notVaildNumber");
              col.valid = null;
              this.$forceUpdate();
            })
        } else this.$forceUpdate();

      },
      // updateValue(data) {
      //   console.log("🚀 ~ file: TextField.vue ~ line 100 ~ updateValue ~ data", data)
      //   this.$emit('update:model', data)
      // }
    },

    computed: {
      ...mapGetters(['loading']),
      valueProp:{
        get: function(){
            return this.model
        },
        set: function(model){
            return this.$emit("update:model", model);
        }
      },
      t_ar() {
        return this.$i18n.locale == 'ar' ? 'text-right': 'text-left';
      },
      f_ar() {
        return this.$i18n.locale == 'ar' ? 'f-right': 'f-left';
      },
    }
  }
</script>