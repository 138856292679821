<template>
  <form  class="mt-8 form-box p-5" v-if='formData.postal_codes && formData.postal_codes.length > 0'>
    <!-- {{formData.postal_codes}} -->
    <div class="row justify-content-between mb-5">
      <div class="download-subheader">{{$t('PostalcodesrangesforDelivery')}}</div>
      <div class="box-buttons ">
        <button class="btn-trans text-info" @click.prevent='addForm'>
          {{$t('Add')}}
          <i class="fa fa-plus text-info"></i>
        </button>
      </div>
    </div>
    <div 
      class="row my-3 "
      v-for='(code, codeIndex) in formData.postal_codes'
      :key='codeIndex'
    > 

      <div class="col my-2">
        <label class="input-label" :for='code.id' :class="[f_ar, ]">{{Object.keys(code)[2]}}</label>
        <input 
          :type="code.type || 'text'"
          class="text-input-sm border px-3"
          :class="[t_ar]"
          v-model='code.from'
          :placeholder="Object.keys(code)[2]"
          :disabled='loading'
          :id='code.id'
        />  
      </div>
      <div class="col my-2">
        <label class="input-label" :for='code.id' :class="[f_ar, ]">{{Object.keys(code)[0]}}</label>
        <input 
          :type="code.type || 'text'"
          class="text-input-sm border px-3"
          :class="[t_ar]"
          v-model='code.to'
          :placeholder="Object.keys(code)[0]"
          :disabled='loading'
          :id='code.id'
        />  
      </div>
      <div class="col my-2">
        <label class="input-label" :for='code.id' :class="[f_ar, ]">{{Object.keys(code)[1]}}</label>
        <input 
          :type="code.type || 'text'"
          class="text-input-sm border px-3"
          :class="[t_ar]"
          v-model='code.cost'
          :placeholder="Object.keys(code)[1]"
          :disabled='loading'
          :id='code.id'
        />  
      </div>
      <div class="col my-2">
        <label class="input-label" :for='code.id' :class="[f_ar, ]">{{Object.keys(code)[3]}}</label>
        <input 
          :type="code.type || 'text'"
          class="text-input-sm border px-3"
          :class="[t_ar]"
          v-model='code.minOrder'
          :placeholder="Object.keys(code)[3]"
          :disabled='loading'
          :id='code.id'
        />  
      </div>
      <div class="col  my-2">
        <label class="input-label" :for='code.id' :class="[f_ar, ]">{{Object.keys(code)[4]}}</label>
        <div class="input-group">
          <div class="input-group-append-sm ">
              <button 
                class="btn-pri-sm dropdown-toggle my-1" 
                type="button" data-toggle="dropdown" 
                aria-haspopup="true" aria-expanded="false"
              >
                {{code.deliveryTimeType}}
              </button>
              <div class="dropdown-menu" >
                <li
                  v-for='(item, index) in deliveryTimeItems'
                  :key='index' 
                  class="dropdown-item pointer" 
                  @click='code.deliveryTimeType = item'
                >
                  {{$t(item)}}
                  
                </li>
              </div>
            </div>
          <input 
            :type="code.type || 'text'"
            class="text-input-sm border radius-1 form-control border px-3"
            :class="[t_ar]"
            v-model='code.deliveryTime'
            :placeholder="Object.keys(code)[4]"
            :disabled='loading'
            :id='code.id'
          />  
        </div>
      </div>
      <div class="col my-2">
        <label class="input-label" :for='code.id' :class="[f_ar, ]">{{Object.keys(code)[6]}}</label>
        <input 
          :type="code.type || 'text'"
          class="text-input-sm border px-3"
          :class="[t_ar]"
          v-model='code.shippingFreeAfter'
          :placeholder="Object.keys(code)[6]"
          :disabled='loading'
          :id='code.id'
        />  
      </div>
      <div class="">
        <button class="btn-icon-trans" @click.prevent="removeForm(code)">
          <i class="fa fa-trash my-5" />
        </button>
      </div>
    </div>

  </form>
</template>

<script>
import { mapActions, mapGetters} from 'vuex';
export default {
  props: ['formData'],
  methods: {
    addForm() {
      this.formData.postal_codes.push(this.postalFields)
    },
    removeForm(code) {
      let index = this.formData.postal_codes.indexOf(code);
      this.formData.postal_codes.splice(index, 1)
    }
  },  
  computed: {
    ...mapGetters(['loading']),
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right': 'text-left';
    },
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right': 'f-left';
    },
    postalFields() {
      return {
        to: '',
        cost: null,
        from: null,
        minOrder: null,
        deliveryTime: null,
        deliveryTimeType: null,
        shippingFreeAfter: null,
      }
    },
    deliveryTimeItems() {
      return [
        'days',
        'minutes',
        'Hours'
      ]
    },
  }
}
</script>

"to": 1050, "cost": 30, "from": 1002, "": 0, "deliveryTime": 15, "deliveryTimeType": "minutes", "shippingFreeAfter": 10 