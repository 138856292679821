var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container px-0"},[_c('div',{staticClass:"row "},[(
      !_vm.profileLoading &&
      _vm.myProducts &&
      _vm.myProducts.results &&
      _vm.myProducts.results.data.length > 0
    )?_c('div',{staticClass:"col-12 px-0"},[(
        !_vm.profileLoading &&
        _vm.myProducts &&
        _vm.myProducts.results &&
        _vm.myProducts.results.data.length > 0
      )?_c('ProductsTable',{attrs:{"items":_vm.myProducts.results.data,"editItem":_vm.editItem,"deleteItem":_vm.deleteItem}}):_c('NoPlaces'),_c('div',{staticClass:"col-12 px-0 mt-5"},[(
          _vm.myProducts &&
          _vm.myProducts.results &&
          _vm.myProducts.results.data.length > 0
        )?_c('Pagination',{attrs:{"currentPage":_vm.page,"showPage":_vm.showPage,"lastPage":_vm.lastPage}}):_vm._e()],1)],1):_c('NoPlaces',{attrs:{"msg":_vm.$t('NoProductsFound'),"imgWith":true,"img":require('../../assets/images/empty.png')}})],1),_c('div',{staticClass:"modal fade pb-9",class:_vm.width == 'sm' ? 'pr-3 ' : '',attrs:{"tabindex":"-1","id":"productsFilterModal"}},[_c('div',{staticClass:"mx-2 modal-dialog",class:_vm.width == 'sm' ? 'w-100' : ['modal-xl', 'mx-auto']},[_vm._m(0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-content filter-contain"},[_c('div',{staticClass:"modal-header"},[_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('i',{staticClass:"fa fa-times"})])]),_c('div',{staticClass:"modal-body"})])}]

export { render, staticRenderFns }