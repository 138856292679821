<template>
  <div>
    <label v-if='field.label' class="input-label" :for='field.id' :class="[f_ar, ]">{{field.label}}</label>
    <div class="input-group">
      <div class="input-group-append-sm ">
        <button 
          class="btn-pri-sm dropdown-toggle my-1" 
          type="button" data-toggle="dropdown" 
          aria-haspopup="true" 
          aria-expanded="false"
        >
          {{'item'}}
        </button>
        <div class="dropdown-menu">
          <li 
            v-for='(item, index) in dropItems' 
            :key='index'
            class="dropdown-item" 
          >
            {{item}}  
          </li>
        </div>
      </div>
      <input 
        :type="field.type || 'text'"
        class="text-input-sm border radius-1 form-control  px-3 "
        :class="[t_ar]"
        v-model='valueProp'
        :placeholder="field.label"
        :disabled='loading'
        :id='field.id'
        :required='field.required'
      />  
    </div>
  </div>
</template>


<script>
import {mapGetters} from 'vuex';
export default {
  props: ['field', 'dropItems', 'model'],
  methods: {

  },

  computed: {
    ...mapGetters(['loading']),
    valueProp:{
      get: function(){
          return this.model
      },
      set: function(model){
          return this.$emit("update:model", model);
      }
    },
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right': 'text-left';
    },
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right': 'f-left';
    },
  }
}
</script>