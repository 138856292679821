<template>
  <div class="container">
    <div class="row">
      <div class="col-12" v-if="
        business && business.id && formData.id && allTags && all_categories
      ">
        <form @submit.prevent="submit">
          <div v-for="(row, index) in formFields" :key="index" class="form-group row my-3" :class="[flex_ar]">
            <div v-for="field in row.cols" :key="field.label" class="px-4 col my-4" :class="[
              flex_ar,
              field.name == 'phone' ? 'input-group' : '',
              field.class,
            ]">
              <PrePendSelect v-if="field.type == 'dropdown'" :model.sync="formData[field.name]"
                :dropItems="field.dropItems" :field="field" />
              <TagsForm v-else-if="
                field.type == 'tags' && Object.keys(allTags).length > 0
              " :tagsItems="allTags" :formData.sync="formData" />
              <CategoryChooseForm v-else-if="
                field.type == 'categorychoose' && all_categories.length > 0
              " :categoryItems="all_categories" :formData.sync="formData" />
              <SelectFieldById v-else-if="field.type == 'select'" :model.sync="formData[field.name]"
                :selectOptions="field.selectOptions" :field="field" />
              <Textarea v-else-if="field.type == 'textarea'" :model.sync="formData[field.name]" :field="field" />
              <TextField v-else :model.sync="formData[field.name]" :field="field" />
            </div>
          </div>
        </form>
        <PostalCodesForm :formData="formData" />
        <ShipMethodsForm :formData="formData" />
        <PaymentMethodsForm :formData="formData" />
        <PaymentMethodsForm :formData="formData" />
        <AppSettings :formData="formData" />
      </div>
      <button class="btn-success" @click="submit">{{ $t("submit") }}</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import cookies from "vue-cookies";
import TextField from "@/components/forms/fields/TextField.vue";
import Textarea from "@/components/forms/fields/Textarea.vue";
import SelectFieldById from "@/components/forms/fields/SelectFieldById.vue";
import PrePendSelect from "@/components/forms/fields/PrePendSelect.vue";
import CategoryChooseForm from "@/components/forms/fields/CategoryChooseForm.vue";
import TagsForm from "@/components/forms/fields/TagsForm.vue";
import PostalCodesForm from "@/components/forms/PostalCodesForm.vue";
import ShipMethodsForm from "@/components/forms/ShipMethodsForm.vue";

export default {
  components: {
    TextField,
    PrePendSelect,
    Textarea,
    SelectFieldById,
    ShipMethodsForm,
    PostalCodesForm,
    CategoryChooseForm,
    TagsForm,
  },
  data() {
    return {
      formData: {},
    };
  },
  props: ["editObj"],
  watch: {
    business: function (newObj, oldObj) {
      if (newObj !== oldObj) {
        this.formData = {
          ...newObj,
          ...newObj.location,
        };
        // console.log(this.formData, this.editObj)
      }
    },
  },
  mounted() {
    this.getBusinessById({
      business_id: this.editObj.id,
      lang: this.$i18n.locale,
    }).then((res) => {
      this.formData = {
        ...this.business,
        ...this.business.location,
      };
    });
    this.getAllTags({
      lang: this.$i18n.locale,
      api_token: cookies.get("user_token"),
    });
    this.getAllCategories({
      lang: this.$i18n.locale,
      api_token: cookies.get("user_token"),
    });
  },
  methods: {
    ...mapActions(["getBusinessById", "getAllTags", "getAllCategories"]),
    submit() {
    },
  },

  computed: {
    ...mapGetters(["business", "allTags", "all_categories"]),
    formFields() {
      return [
        {
          cols: [
            { label: this.$t("col_name"), name: "name", id: "name" },
            {
              label: this.$t("description"),
              name: "description",
              id: "description",
              type: "textarea",
            },
          ],
        },
        {
          cols: [
            {
              label: this.$t("categories"),
              name: "categories",
              id: "categories",
              type: "categorychoose",
            },
            { label: this.$t("tags"), name: "tags", id: "tags", type: "tags" },
          ],
        },
        {
          cols: [
            {
              label: this.$t("country"),
              name: "country_id",
              id: "country",
              type: "select",
              selectOptions: this.countryItems,
            },
            {
              label: this.$t("state"),
              name: "city_id",
              id: "state",
              type: "select",
              selectOptions: this.stateItems,
            },
            {
              label: this.$t("col_area"),
              name: "area_id",
              id: "area",
              type: "select",
              selectOptions: this.areaItems,
            },
            {
              label: this.$t("col_phone"),
              name: "phone",
              id: "phone",
              mask: "49###############",
            },
          ],
        },
        {
          cols: [
            { label: this.$t("webSiteLink"), name: "website", id: "website" },
            { label: this.$t("facebook"), name: "facebook", id: "facebook" },
            { label: this.$t("twitter"), name: "twitter", id: "twitter" },
            { label: this.$t("youtube"), name: "youtube", id: "youtube" },
            { label: this.$t("instagram"), name: "instagram", id: "instagram" },
          ],
        },
        {
          cols: [
            {
              label: this.$t("Busaddress"),
              name: "address",
              id: "address",
              type: "textarea",
            },
            {
              label: this.$t("addressLink"),
              name: "address_Link",
              id: "addressLink",
            },
            { label: this.$t("longitude"), name: "longitude", id: "longitude" },
            { label: this.$t("latitude"), name: "latitude", id: "latitude" },
          ],
        },
      ];
    },
    countryItems() {
      return [
        {
          id: 4,
          name: "Germany",
        },
        {
          id: 1,
          name: "Egypt",
        },
      ];
    },
    stateItems() {
      return [
        {
          id: 28,
          name: "saxony",
        },
        {
          id: 3,
          name: "mitte",
        },
      ];
    },
    areaItems() {
      return [
        {
          id: 645,
          name: "area 1",
        },
        {
          id: 3,
          name: "area2",
        },
      ];
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    t_ar_reverse() {
      return this.$i18n.locale == "ar" ? "text-left" : "text-right";
    },
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "";
    },
    d_ar() {
      return this.$i18n.locale == "ar" ? "dir-rtl" : "";
    },
  },
};
</script>