<template>
  <div class="row mx-3 mt-8">
    <div class="col-12">
      <!-- {{formData}} -->
      <form class="" @submit.prevent="submit">
        <div v-for="(row, index) in formFields" :key="index" class="form-group row my-3" :class="[flex_ar]">
          <div v-for="field in row.cols" :key="field.label" class="px-4 col my-4" :class="[
            flex_ar,
            field.name == 'phone' ? 'input-group' : '',
            field.class,
          ]">
            <label class="input-label" :for="field.id" :class="[f_ar]">{{ field.label }}</label>
            <span v-if="field.type == 'file'" class="file-field-name">{{ fileName }}</span>
            <textarea v-if="field.type == 'textarea'" class="textarea-grey" rows="5"
              v-model="formData[field.name]"></textarea>
            <select v-else-if="field.type == 'dropdown'" class="text-input-grey">
              <option value="0" selected>{{ $t("none") }}</option>
              <option v-for="(opt, i) in field.options" :key="i" :value="field.name"> {{ opt }} </option>
            </select>
            <input v-else :type="field.type || 'text'" class="text-input-grey px-3" :class="[
              t_ar,
              field.type == 'file' ? 'file-field' : '',
              !field.disabled ? 'border' : '',
              field.type == 'checkbox'
                ? $i18n.locale == 'ar'
                  ? 'custom-control-input-grey-ar'
                  : 'custom-control-input-grey'
                : '',
            ]" v-model="formData[field.name]" :placeholder="field.label" :disabled="field.disabled" :id="field.id"
              @change="changeFile" />
            <small v-if="field.error || field.vaild" :class="field.valid ? 'text-success' : 'text-error'"> {{
                field.valid || field.error
            }} </small>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      formData: {},
      fileName: "",
    };
  },
  props: ["item"],
  mounted() {
    this.showProduct({
      product_id: this.item.id,
      lang: this.$i18n.locale,
    }).then((res) => {
      this.formData = { ...this.product };
    });
  },
  methods: {
    ...mapActions(["showProduct", "getBusinessById"]),
    submit() {
    },
    changeFile() {
      // console.log('asdasd')
      if (document.getElementById("file-id").files[0]) {
        this.fileName = document.getElementById("file-id").files[0].name;
      } else console.log("nothing");
    },
    addProductOption() {
      this.formData.options.push();
    },
  },

  computed: {
    ...mapGetters(["product", "loading", "business"]),
    formFields() {
      return [
        {
          cols: [
            {
              label: this.$t("image"),
              name: "image",
              id: "image",
              type: "file",
            },
            { label: this.$t("Name"), name: "name", id: "name" },
            { label: this.$t("SKU"), name: "SKU", id: "SKU" },
            { label: this.$t("Brand"), name: "brand", id: "brand" },
            { label: this.$t("Business"), name: "business", id: "business" },
          ],
        },
        {
          cols: [
            {
              label: this.$t("description"),
              name: "description",
              id: "description",
              type: "textarea",
            },
            {
              label: this.$t("categories"),
              name: "categories",
              id: "categories",
              type: "dropdown",
            },
            {
              label: this.$t("childCategories"),
              name: "childCategories",
              id: "childCategories",
              type: "dropdown",
            },
          ],
        },
        {
          cols: [
            { label: this.$t("tax"), name: "tax", type: "number" },
            {
              label: this.$t("status"),
              name: "status",
              type: "dropdown",
              options: this.statusOptions,
            },
            {
              label: this.$t("maxQuantityPerOrder"),
              name: "maxQuantityPerOrder",
              type: "number",
            },
            { label: this.$t("featured"), name: "featured", type: "dropdown" },
          ],
        },
      ];
    },
    optionFields() {
      return {};
    },
    ProductOptionsFields() {
      return [
        { label: this.$t("label"), name: "label", rowClass: "border radius-1" },
        { label: this.$t("price"), name: "price" },
        {
          label: this.$t("priceAfterSale"),
          name: "priceAfterSale",
          type: "number",
        },
        { label: this.$t("quantity"), name: "quantity", type: "number" },
      ];
    },
    statusOptions() {
      return [true, false];
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    t_ar_reverse() {
      return this.$i18n.locale == "ar" ? "text-left" : "text-right";
    },
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "";
    },
    d_ar() {
      return this.$i18n.locale == "ar" ? "dir-rtl" : "";
    },
  },
};
</script>