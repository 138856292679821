<template>
  <form class="form-box my-5 mt-8 p-5" v-if='formData.shipping_methods && formData.shipping_methods.length > 0'>
    <div class="row justify-content-between mb-5">
      <div class="download-subheader">{{$t('shippingMethod')}}</div>
    </div>
      <div 
        class="row my-5 "
        v-for='(method, methodIndex) in formData.shipping_methods'
        :key='methodIndex'
      > 
      {{method}}
        <div 
          class="col my-2"
          v-for='(field) in shipFields'  
          :key='field.id'
        >
          <div class="form-check" v-if='shipping_methods.length > 0 && show == true'>
            <label v-if='field.label && field.type == "checkbox"' class="input-label form-check-label" :for='field.id' :class="[f_ar, ]">{{method.name}}</label>
            <input  
              v-if='field.type == "checkbox"'
              :type='field.type'
              class="form-control w-auto"
              v-model='formData.shipping_methods[methodIndex].status'
            />
          </div>
          <TextField  
            v-if='field.type != "checkbox" && shipping_methods.length > 0 && show == true'
            :field='field'
            :model.sync='shipping_methods[methodIndex][field.name]'
          />
        </div> 
      </div>
  </form>
</template>

<script>
import { mapActions, mapGetters} from 'vuex'; 
import TextField from '@/components/forms/fields/TextField.vue';
import SelectFieldById from '@/components/forms/fields/SelectFieldById.vue';

export default {
  data() {
    return {
      shipping_methods: [],
      show: false, 
    }
  },
  components: {
    TextField,
    SelectFieldById,
  },
  props: ['formData'],
  mounted() {
    this.initiateShipping();
  },
  methods: {
    addForm() {
      this.formData.shipping_methods.push(this.shipFields)
    },
    removeForm(code) {
      let index = this.formData.shipping_methods.indexOf(code);
      this.formData.shipping_methods.splice(index, 1)
    },
    getShipName(id) {
      return this.shipMethodsItems.find(el => el.id == id).name;
    },
    initiateShipping() {
      this.formData.shipping_methods.foreach(el => el.status = true);
      this.shipping_methods = [...this.formData.shipping_methods];
      this.shipMethodsItems.forEach(el => {
        if(this.shipping_methods.find(method => method.id == el.id)) {
          console.log('method')
        }else this.shipping_methods.push(el)
      })
      this.show = true;
      this.$forceUpdate()
    }
  },
  computed: {
    ...mapGetters(['loading']),
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right': 'text-left';
    },
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right': 'f-left';
    },
    shipMethodsItems() {
      return  [
        { name: this.$t('pickupfromstore') , id: 1, delivery_price: '', delivery_time: '', delivery_note: ''},
        { name: this.$t('storeDelivery') , id: 2, delivery_price: '', delivery_time: '', delivery_note: ''},
        { name: this.$t('shippingByPost') , id: 3, delivery_price: '', delivery_time: '', delivery_note: ''},
        { name: this.$t('dineIn') , id: 4, delivery_price: '', delivery_time: '', delivery_note: '' },
      ]
      
    },
    shipFields() {
      return [
        { label: this.$t('ShippingAndDelivery'), name: 'name', id: 'ShippingAndDelivery',  type: 'checkbox' },
        { label: this.$t('DeliveryPrice'), name: 'delivery_price', id: 'DeliveryPrice',  type: 'number' },
        { label: this.$t('DeliveryTime'), name: 'delivery_time', id: 'DeliveryTime',  type: 'text' },
        { label: this.$t('ShipFreeAfter'), name: 'delivery_note', id: 'ShipFreeAfter',  type: 'number' },
      ]
    }
  }
}
</script>