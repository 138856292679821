<template>
<div>
  <label v-if='field.label' class="input-label" :for='field.id' :class="[f_ar, ]">{{field.label}}</label>
  <textarea 
    class="textarea-grey" 
    rows="5"  
    v-model='valueProp'
  ></textarea>
</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      data: null,
    }
  },
  props: ['model', 'field'],
  watch: {
    model: function (newMod, oldMod) {
      if(newMod !== oldMod) {
        this.data = newMod;
      }
    }
  },
  mounted() {
    this.data = this.model;
  },
  methods: {
    updateValue(data) {
      this.$emit('update:model', data)
    }
  },
  computed: {
    ...mapGetters(['loading']),
    valueProp:{
      get: function(){
          return this.model
      },
      set: function(model){
          return this.$emit("update:model", model);
      }
    },
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right': 'text-left';
    },
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right': 'f-left';
    },
  }
}
</script>