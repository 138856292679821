<template>
  <div class="conatiner p-0">
    <div class="row" :class="width == 'lg' ? 'mx-3' : ''">
      <div class="col-12 text-center px-0">
      </div>
      <div class="col-12 px-0" v-if="favourites && favourites.length > 0">
        <div class="row" :class="flex_ar">
          <div class="col-6 px-1 px-lg-3" v-for='(fav, index) in favourites' :key='index'>
            <div class="row pop-card shadow-eff fave-in p-0" :class="flex_ar">
              <div class="col-12 product-image px-0 col-sm-3 text-center align-self-center">
                <img class="in-fav image"
                  v-if="fav.business && fav.business.media && fav.business.media[0] || fav.product && fav.product.media && fav.product.media[0]"
                  :src='fav.business && fav.business.media && fav.business.media[0] ? fav.business.media[0].url : fav.product && fav.product.media && fav.product.media[0] ? fav.product.media[0].url : ""' />
                <img class="in-fav image placeHolder" v-else :src='placeHolder' />
                <div class='main-fav-btn' :class="f_ar_reverse" @click='favouriteDelete(fav)'>
                  <i class="fa fa-heart"></i>
                </div>
              </div>
              <div class="col-12 col-sm-9 px-0 align-self-center">
                <div class="row align-items-center px-2 mt-2 mb-1 justify-content-between" :class="flex_ar">
                  <div class="pop-head text-ellipsis" :class="t_ar"> {{ fav.business ? fav.business.name : fav.product ?
                      fav.product.name : ''
                  }} </div>
                  <div class="opt-label" :class="t_ar" v-if='fav.product && fav.product.price'>
                    <span class=" "> {{ fav.product.price_after_sale > 0 ? fav.product.price_after_sale :
                        fav.product.price
                    }} € </span>
                    <span class="old-price " v-if='large && fav.product.price_after_sale > 0'> {{ fav.product.price }} €
                    </span>
                  </div>
                  <div class="data-sub mouse" v-if="fav.business && fav.business.category"
                    v-html='fav.business.category.name'>
                  </div>
                </div>
                <div class="row" :class="t_ar">
                  <div class="col-12 px-2">
                    <span class="blacked data-sub">{{ fav.business ? $t('business') : $t('product') }}</span>
                  </div>
                </div>
                <div class="col-12 mb-2">
                  <button class="btn-pri mt-lg-4 mt-2 w-100"
                    @click="fav.product ? goProduct(fav.product.business.id, 3, fav.product.id) : goBusinss(fav.business.id)">
                    <i class="fa fa-info-circle"></i> {{ $t('details') }} </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NoPlaces :msg="$t('NoFavourites')" v-else :imgWith="true" :img="require('../../assets/images/empty.png')" />
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
// import PopProduct from '@/components/Listings/PopProduct.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import cookies from 'vue-cookies';
import Stars from '@/components/misc/Stars.vue';
import NoPlaces from '@/components/misc/NoPlaces.vue';

export default {
  components: {
    Stars,
    NoPlaces
  },
  data() {
    return {
      large: true,
      showFavs: 'products',
      placeHolder: require('../../assets/images/logo/logo-icon.png'),
      favs: [
        { name: 'Pepperoni Pizza With Basil Leaves', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus nisi, faucibus pretium urna eu, laoreet', price: '200$' },
        { name: 'Pepperoni Pizza With Basil Leaves', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus nisi, faucibus pretium urna eu, laoreet', price: '200$' },
        { name: 'Pepperoni Pizza With Basil Leaves', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus nisi, faucibus pretium urna eu, laoreet', price: '200$' },
      ]
    }
  },
  mounted() {
    this.getUserFavourites({
      api_token: cookies.get('user_token'),
      locale: this.$i18n.locale,
    });
  },
  computed: {
    ...mapGetters(['favourites', 'width']),
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right' : 'f-left';
    },
    f_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'f-left' : 'f-right';
    },
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right' : 'text-left';
    },
    t_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'text-left' : 'text-right';
    },
    flex_ar() {
      return this.$i18n.locale == 'ar' ? 'flex-row-reverse' : '';
    },
  },
  methods: {
    ...mapActions(['getUserFavourites', 'deleteFavourite']),
    ...mapMutations(['showMsg']),
    changeShowFavs() {
      if (this.showFavs == 'products') {
        this.showFavs = 'business';
      } else this.showFavs = 'products';
    },
    showFavsTab() {
      if (this.showFavs == 'products') {
        return this.$t('business');
      } else return this.$t('product');
    },
    goProduct(id, tab = 3, productId) {
      this.$router.push({
        name: 'BusinessProfile',
        params: {
          locale: this.$i18n.locale,
          id,
          tab,
          tabID: tab == 3 ? 'rel' : '',
          productId,
        }
      })
    },
    goBusinss(id, tab = 1) {
      this.$router.push({
        name: 'BusinessProfile',
        params: {
          locale: this.$i18n.locale,
          id,
        }
      })
    },
    favouriteDelete(item) {
      this.showMsg({
        type: 'warning',
        text: this.$t('SureDeleteFavourite'),
        close: true,
        condition: 7,
        item: {
          ...item,
          api_token: cookies.get('user_token')
        },
      });
    },
  }
}
</script>