var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[(
      _vm.business && _vm.business.id && _vm.formData.id && _vm.allTags && _vm.all_categories
    )?_c('div',{staticClass:"col-12"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},_vm._l((_vm.formFields),function(row,index){return _c('div',{key:index,staticClass:"form-group row my-3",class:[_vm.flex_ar]},_vm._l((row.cols),function(field){return _c('div',{key:field.label,staticClass:"px-4 col my-4",class:[
            _vm.flex_ar,
            field.name == 'phone' ? 'input-group' : '',
            field.class ]},[(field.type == 'dropdown')?_c('PrePendSelect',{attrs:{"model":_vm.formData[field.name],"dropItems":field.dropItems,"field":field},on:{"update:model":function($event){return _vm.$set(_vm.formData, field.name, $event)}}}):(
              field.type == 'tags' && Object.keys(_vm.allTags).length > 0
            )?_c('TagsForm',{attrs:{"tagsItems":_vm.allTags,"formData":_vm.formData},on:{"update:formData":function($event){_vm.formData=$event},"update:form-data":function($event){_vm.formData=$event}}}):(
              field.type == 'categorychoose' && _vm.all_categories.length > 0
            )?_c('CategoryChooseForm',{attrs:{"categoryItems":_vm.all_categories,"formData":_vm.formData},on:{"update:formData":function($event){_vm.formData=$event},"update:form-data":function($event){_vm.formData=$event}}}):(field.type == 'select')?_c('SelectFieldById',{attrs:{"model":_vm.formData[field.name],"selectOptions":field.selectOptions,"field":field},on:{"update:model":function($event){return _vm.$set(_vm.formData, field.name, $event)}}}):(field.type == 'textarea')?_c('Textarea',{attrs:{"model":_vm.formData[field.name],"field":field},on:{"update:model":function($event){return _vm.$set(_vm.formData, field.name, $event)}}}):_c('TextField',{attrs:{"model":_vm.formData[field.name],"field":field},on:{"update:model":function($event){return _vm.$set(_vm.formData, field.name, $event)}}})],1)}),0)}),0),_c('PostalCodesForm',{attrs:{"formData":_vm.formData}}),_c('ShipMethodsForm',{attrs:{"formData":_vm.formData}}),_c('PaymentMethodsForm',{attrs:{"formData":_vm.formData}}),_c('PaymentMethodsForm',{attrs:{"formData":_vm.formData}}),_c('AppSettings',{attrs:{"formData":_vm.formData}})],1):_vm._e(),_c('button',{staticClass:"btn-success",on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("submit")))])])])}
var staticRenderFns = []

export { render, staticRenderFns }