<template>
<div>
  <label v-if='field.label' class="input-label" :for='field.id' :class="[f_ar, ]">{{field.label}}</label>
  <select 
    class="text-input-grey"
    v-model="data"
    @change='updateValue(data)'
  >
    <option value="0">{{$t('none')}}</option>
    <option 
      v-for='(opt, i) in selectOptions' 
      :key='i'
      :value="opt"
      v-html='opt.name'
    >
    </option>
  </select>
</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      data: null,
    }
  },
  props: ['field', 'model', 'selectOptions'],
  watch: {
    model: function (newMod, oldMod) {
      if(newMod !== oldMod) {
        this.data = newMod
      }
    }
  },
  mounted() {
    this.data = this.model;
  },
  methods: {
    updateValue(data) {
      this.$emit('update:model', data)
    }
  },
  computed: {
    ...mapGetters(['loading']),
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right': 'text-left';
    },
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right': 'f-left';
    },
  },
}
</script>