<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-11">
        <div class="row" :class="flex_ar">
          <div
            class="col-12 col-md-6 my-3"
            v-for="(set, index) in settings"
            :key="index"
          >
            <div class="card-desc row mx-0 mx-md-2" :class="flex_ar">
              <div class="col-12 col-md-10" :class="t_ar">
                <div class="business-footer-name">
                  {{ set.label }}
                </div>
                <div class="data-sub">
                  {{ set.description }}
                </div>
              </div>
              <div class="col-12 col-md-2">
                <div
                  class="custom-control custom-switch d-flex"
                  :class="[flex_ar, $i18n.locale == 'ar' ? 'mr-5' : '']"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :id="index"
                    v-model="set.value"
                  />
                  <label
                    class="
                      custom-control-label custom-cont
                      rol-label-switch
                      privacy-header
                    "
                    :for="index"
                    :class="
                      $i18n.locale == 'ar' ? 'custom-control-label-ar' : ''
                    "
                  >
                    <!-- <span class="mx-2">{{set.label}}</span> -->
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      settings: [
        {
          label: "Order Confirmation",
          description:
            "You will be notifications when customer order any products",
          value: true,
        },
        {
          label: "Order Delivered",
          description: "You will be notifications once the order is delivered",
          value: false,
        },
        {
          label: "Email Notificotion",
          description:
            "Turm on email notification to get updates through email",
          value: true,
        },
        {
          label: "Order Confirmation",
          description:
            "You will be notifications when customer order any products",
          value: true,
        },
        {
          label: "Order Delivered",
          description: "You will be notifications once the order is delivered",
          value: false,
        },
        {
          label: "Email Notificotion",
          description:
            "Turm on email notification to get updates through email",
          value: true,
        },
      ],
    };
  },
  computed: {
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "";
    },
  },
};
</script>