<template>
  <div class="container px-0">
    <div class="row justify-content-center" v-if="!profileLoading && myReservations && items && items.length > 0">
      <div v-for="(item, index) in items" :key="index" class="col-12 col-md-11 col-lg-10 my-4 border-bottom py-4 px-0"
        @click="openModal(item)">
        <div class="row" :class="flex_ar" v-if="width != 'sm'">
          <div class="col-4 col-md-2 col-xl-1 align-self-center px-0">
            <img class="radius-1" width="80px" :src="item.business.media[0].icon" />
          </div>
          <div class="col-8 px-0 col-md-2 col-xl-3" :class="t_ar">
            <div class="opt-name text-underline mb-1 text-ellipsis"> {{ item.business.name }} </div>
            <div class="data-label mt-1">#{{ item.id }}</div>
            <div class="data-label mt-1 text-ellipsis" v-if="item.time"> {{ item.time.name }} </div>
          </div>
          <div class="col-12 px-0 col-md-2 text-center align-self-center py-3">
            <div class="subheader-home">{{ item.on_date }}</div>
          </div>
          <div class="col-12 px-0 col-md-2 text-center align-self-center py-3">
            <div class="subheader-home"> {{ item.from ? item.from : item.time.time }} </div>
          </div>
          <div class="col-12 px-0 col-md-2 text-center align-self-center py-3">
            <div class="subheader-home"> {{ item.to ? item.to : item.time.to_time }} </div>
          </div>
          <div class="col-12 px-0 col-md-2 align-self-center py-3 text-center">
            <button class="btn-pri mt-2 pointer" @click="openModal(item)">
              <!-- <i class="fa fa-info"></i> --> {{ $t("details") }}
            </button>
          </div>
        </div>
        <div class="row" :class="flex_ar" v-if="width == 'sm'">
          <div class="col-3 px-0 align-self-center">
            <img class="rounded" width="80px" :src="item.business.media[0].icon" />
          </div>
          <div class="col-9 px-0" :class="t_ar">
            <div class="opt-name text-underline mb-1 text-ellipsis"> {{ item.business.name }} </div>
            <div class="data-label mt-1">#{{ item.id }}</div>
            <div class="data-label mt-1 text-ellipsis" v-if="item.time"> {{ item.time.name }} </div>
          </div>
          <div class="col-12px-0 my-3">
            <div class="opt-label" :class="t_ar"> {{ $t("ReserveDate") }} <span class="opt-name" :class="f_ar_reverse">
                {{ item.on_date }} </span>
            </div>
            <div class="opt-label" :class="t_ar"> {{ $t("ReserveFrom") }} <span class="opt-name" :class="f_ar_reverse">
                {{ item.from ? item.from : item.time.time }} </span>
            </div>
            <div class="opt-label" :class="t_ar"> {{ $t("ReserveTo") }} <span class="opt-name" :class="f_ar_reverse"> {{
                item.to ? item.to : item.time.to_time
            }} </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-5">
        <Pagination v-if="items.length > 0" :currentPage="page" :showPage="showPage" :lastPage="lastPage"></Pagination>
      </div>
    </div>
    <NoPlaces v-else :msg="$t('noReservations')" :imgWith="true" :img="require('../../assets/images/empty.png')" />
    <div class="modal fade pb-9" :class="width == 'sm' ? 'pr-3 ' : ''" tabindex="-1" id="appointmentHistory">
      <div class="mx-2 modal-dialog" :class="width == 'sm' ? 'w-100' : ['modal-xl', 'mx-auto']">
        <div class="modal-content filter-contain">
          <!-- <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" @click="closeModal" aria-label="Close">
              <i class="fa fa-times"></i>
            </button>
          </div> -->
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <ul class="nav nav-tabs modal-tabs" :class="flex_ar">
                  <li :class="modalShow == 'tickets' ? 'active' : ''" class="nav-item modal-tabs-link"
                    @click="changeTab('tickets')"> {{ $t("Tickets") }} </li>
                  <li :class="modalShow == 'details' ? 'active' : ''" class="nav-item modal-tabs-link"
                    @click="changeTab('details')"> {{ $t("ReservationDetails") }} </li>
                </ul>
                <div class="my-4">
                  <Tickets v-if="modalShow == 'tickets'" :mytickets="modalData" />
                  <ReserveDetails v-if="modalShow == 'details'" :item="modalData" />
                  <NoPlaces v-if="modalLoading" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <span>
              <button @click="goPay" class="btn-pri" v-if="
                modalData.total_amount > 0 &&
                (modalData.payments.length == 0 ||
                  modalData.payments[0].status != 1)
              ">
                <i class="fa fa-paypal mx-1" :class="f_ar"></i> {{ $t("PaypalPay") }} </button>
              <button @click="attachOrder" class="btn-pri"> {{ $t("ConnectOrder") }} </button>
            </span>
            <button @click="closeModal" class="btn-error"> {{ $t("Close") }} </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import cookies from "vue-cookies";
import Tickets from "@/components/order/Tickets.vue";
import ReserveDetails from "@/components/order/ReserveDetails.vue";
import NoPlaces from "@/components/misc/NoPlaces.vue";
import Pagination from "@/components/misc/Pagination.vue";
import { helpers } from "../../Helpers";

export default {
  components: {
    Tickets,
    NoPlaces,
    ReserveDetails,
    Pagination,
  },
  data() {
    return {
      page: 1,
      modalData: {},
      modalShow: "tickets",
    };
  },
  mixins: [helpers],
  mounted() {
    this.getMyReservations({
      page: this.page,
      api_token: cookies.get("user_token"),
      lang: this.$i18n.locale,
    })
      .then((res) => {
        if (this.$route.params.reservation_id) {
          let recentReserve = this.items.filter(
            (el) => el.id == this.$route.params.reservation_id
          )[0];
          this.openModal(recentReserve);
        }
      })
      .catch((err) => console.log(err));
  },
  methods: {
    ...mapActions(["getMyReservations"]),
    openModal(appointment) {
      $("#appointmentHistory").modal("show");
      this.modalData = appointment;
    },
    closeModal() {
      $(".modal").modal("hide");
      this.modalData = {};
    },
    changeTab(tab) {
      this.modalShow = tab;
    },
    showPage(page) {
      if (page != this.page) {
        console.log(page, this.page);
        this.page = page;
        this.getMyReservations({
          page: this.page,
          api_token: cookies.get("user_token"),
        });
      }
    },
    goPay() {
      $(".modal").modal("hide");
      let secretKey = this.createReserveKey(
        this.modalData.created_at,
        this.modalData.id,
        this.user.id,
        this.modalData.business.id
      );
      this.$router.push({
        name: "Payment",
        params: {
          total: this.modalData.total_amount,
          client_id: this.modalData.business.paypal_client_id,
          client_secret: this.modalData.business.paypal_secret,
          reservation_id: this.modalData.id,
          secret_word: secretKey,
        },
      });
    },
    attachOrder() {
      $(".modal").modal("hide");
      var show = "";
      if (this.modalData.business.style_id) {
        if (this.modalData.business.style_id == 3) {
          show = "Yes";
        } else {
          show = "No";
        }
      }
      this.$router.push({
        name: "AttachOrder",
        params: {
          reservation_id: this.modalData.id,
          id: this.modalData.business_id,
          show,
        },
      });
    },
  },
  computed: {
    ...mapGetters([
      "width",
      "myReservations",
      "profileLoading",
      "modalLoading",
      "user",
    ]),
    items() {
      return this.myReservations.data;
    },
    lastPage() {
      return this.myReservations.last_page;
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "";
    },
    flex_ar_reverse() {
      return this.$i18n.locale == "ar" ? "" : "flex-row-reverse";
    },
  },
};
</script>