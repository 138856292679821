<template>
<div>
  <label v-if='field && field.label' class="input-label" :for='field.id' :class="[f_ar, ]">{{field.label}}</label>
  <select 
    class="text-input-grey"
    v-model="valueProp"
  >
    <option value="0">{{$t('none')}}</option>
    <option 
      v-for='(opt, i) in selectOptions' 
      :key='i'
      :value="opt.id"
    >
      {{opt.name}}
    </option>
  </select>
</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      data: null,
    }
  },
  props: ['field', 'model', 'selectOptions'],
  computed: {
    ...mapGetters(['loading']),
    valueProp:{
      get: function(){
          return this.model
      },
      set: function(model){
          return this.$emit("update:model", model);
      }
    },
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right': 'text-left';
    },
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right': 'f-left';
    },
  },
}
</script>