<template>
  <div class="">
    <div class="row justify-content-center px-0">
      <div class="opt-name  d-flex flex-column align-items-center" v-if="!isVerified"> {{ $t("PleaseVerifyAccount") }}
        <button class="btn-success" @click="goVerify"> {{ $t("verifyAccount") }} </button>
      </div>
      <div class="col-12 col-md-11 px-0" v-if="user">
        <form @submit.prevent="updateAccount()" class="my-4">
          <div v-for="(row, index) in formFields" :key="index" class="form-group row my-3" :class="flex_ar">
            <div v-for="field in row.cols" :key="field.label" class="col-12 col-lg-6 px-4"
              :class="[flex_ar, field.name == 'phone' ? 'input-group' : '']">
              <label class="input-label" :for="field.id"
                :class="[f_ar, field.type == 'file' ? 'file-field-label ' : '']">{{ field.label }}</label>
              <span v-if="field.type == 'file'" class="file-field-name">{{ fileName }}</span>
              <input v-if="field.name != 'phone'" :type="field.type || 'text'" class="text-input-grey px-3"
                :class="[t_ar, field.class, !field.disabled ? 'border' : '']" v-model="formData[field.name]"
                :placeholder="field.label" :id="field.id" @change="changeFile" />
              <input v-else :type="field.type || 'text'" class="text-input-grey px-3"
                :class="[t_ar, field.class, !field.disabled ? 'border' : '']" v-model="formData[field.name]"
                :placeholder="field.label" :id="field.id" v-mask="field.mask" :masked="true"
                @blur="validatePhone(field)" />
              <small v-if="field.name == 'phone' && (field.error || field.vaild)"
                :class="field.valid ? 'text-success' : 'text-error'"> {{ field.valid || field.error }} </small>
            </div>
          </div>
          <div class="form-group text-center mt-5 my-4 d-flex align-items-center justify-content-center">
            <button class="w-50" :disabled="loading" :class="loading ? ['btn-def', 'text-center'] : 'btn-success'"> {{
                $t("save")
            }} </button>
          </div>
        </form>
      </div>
      <NoPlaces v-else />
    </div>
    <div class="row justify-content-center my-2">
      <div class="col-12 col-md-11 px-0">
        <div class="row" :class="flex_ar">
          <div class="col-12 px-0" :class="ar ? 'ml-auto' : 'mr-auto'">
            <div class="opt-name my-2" :class="[t_ar]"> {{ $t("myAddresses") }} </div>
            <!-- <div class="opt-name my-2" v-if="!user.latitude">
          {{$t('PleaseSetDefaultAddress')}}
          <button class="btn-success" @click='defaultAddress=true;openModal()'> {{$t('setDefaultAddress')}}</button>
        </div> -->
            <div class="row">
              <template v-for="(add, ind) in userAddresses">
                <div v-if="userAddresses.length > 0" class="card-desc row my-2 mx-auto col-12 col-lg-5 " :class="[
                  flex_ar,
                  checkDefaultAddress(add) ? 'card-desc-active' : '',
                ]" :key="ind">
                  <!-- {{checkDefaultAddress(add)}} -->
                  <div class="col-2 px-0 d-flex align-items-center justify-content-center">
                    <img src="../../assets/images/logo/logo-icon.png" />
                  </div>
                  <div class="col-5 col-md-6 col-lg-7 px-0" :class="t_ar">
                    <div class="opt-name">{{ add.address }}</div>
                    <div class="data-sub">{{ add.postal_code }}</div>
                  </div>
                  <div class="px-0 col-5 col-md-4 col-lg-3 d-flex align-items-center justify-content-around flex-wrap">
                    <button class="main-fav-btn in-result" :disabled="loading" @click="adding = false; openModal(add);">
                      <i class="fa fa-pencil" />
                    </button>
                    <button class="main-fav-btn in-result mx-1" :disabled="loading" @click="addressDelete(add)">
                      <i class="fa fa-trash text-error" />
                    </button>
                    <button class="main-fav-btn in-result has-text text-nowrap mt-2 w-100"
                      v-if="!checkDefaultAddress(add)" @click="defaultAddressAdd(add)"> {{ $t("setDefault") }} </button>
                    <button class="main-fav-btn in-result has-text w-100 mt-2" v-if="checkDefaultAddress(add)"> {{
                        $t("Default")
                    }} </button>
                  </div>
                </div>
              </template>
              <div class="card-desc row my-4 pointer col-12 col-lg-6 mx-auto" :class="flex_ar" @click="
  adding = true;
openModal();
              ">
                <div class="col-2">
                  <button class="btn-icon-trans border" :disabled="loading">
                    <i class="fa fa-plus" />
                  </button>
                </div>
                <div class="col-10 align-self-center" :class="t_ar">
                  <span class="opt-label">{{ $t("addNewAddress") }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-12 col-md-5 col-xl-4">
        <div class="opt-name my-5" :class="t_ar">{{$t('yourQR')}}</div>
        <ProfileQR />
      </div> -->
        </div>
      </div>
    </div>
    <div class="modal fade pb-9" :class="width == 'sm' ? 'pr-3 ' : ''" tabindex="-1" id="addressModal">
      <div class="mx-2 modal-dialog" :class="width == 'sm' ? 'w-100' : ['modal-xl', 'mx-auto']">
        <div class="modal-content filter-contain">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <UserAddressForm v-if="!defaultAddress" :formFields="addressFields"
                  :formSubmit="adding ? addressSubmit : addressUpdate" :initialData="editItem" />
                <div class="" v-if="false">
                  <div class="my-4 pro-header"> {{ $t("EnterPostalCode") }} </div>
                  <LocationForm :defaultAddress="defaultAddress" :closeModal="closeModal" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import UserAddressForm from "@/components/forms/UserAddressForm.vue";
import LocationForm from "@/components/forms/LocationForm.vue";
import NoPlaces from "@/components/misc/NoPlaces.vue";
import ProfileQR from "@/components/profile/ProfileQR.vue";
import cookies from "vue-cookies";

export default {
  components: {
    UserAddressForm,
    NoPlaces,
    LocationForm,
    ProfileQR,
  },
  data() {
    return {
      formData: {},
      editItem: "",
      adding: true,
      fileName: "",
      defaultAddress: false,
    };
  },
  props: ["editFields", "editProfile"],
  mounted() {
    if (!this.user.name) {
      this.retrieveUser();
    }
    this.getUserAddresses(this.user.api_token)
      .then((res) => {
        if (
          this.user &&
          this.user.longitude == null &&
          this.userAddresses &&
          this.userAddresses.length > 0
        ) {
          this.setDefaultAddress({
            postal_code: this.userAddresses[0].postal_code,
            api_token: cookies.get("user_token"),
          }).then((res) => {
            cookies.set("defaultAddressId", item.id);
          });
        }
      })
      .catch((err) => console.log(err));
    this.initiateData();

    //making the first address as default if there is not
  },
  watch: {
    user: function (newUser, oldUser) {
      if (newUser !== oldUser) {
        this.initiateData();
      }
    },
    // userAddresses: function(newAdresses, oldAddresses) {
    //   if (oldAddresses.length == 0 && newAdresses.length == 1 && this.user.longitude == null) {
    //     this.setDefaultAddress({
    //       postal_code: newAdresses[0].postal_code,
    //       api_token: cookies.get('user_token')
    //     }).then(res => {
    //       console.log(res)
    //       cookies.set('defaultAddressId', item.id)
    //     })
    //   }
    // }
  },

  methods: {
    ...mapActions([
      "retrieveUser",
      "getUserAddresses",
      "updateProfile",
      "createAddress",
      "updateAddress",
      "deleteAddress",
      "userLogout",
      "setDefaultAddress",
      "checkPhone",
    ]),
    ...mapMutations(["showMsg"]),

    initiateData() {
      this.formData = {
        name: this.user.name,
        email: this.user.email,
        phone: this.user.phone ? this.user.phone : "",
        country: this.user.country,
        state: this.user.state,
      };
    },
    updateAccount() {
      this.updateProfile({
        ...this.formData,
        newImage: document.getElementById("file-id").files[0],
        phone_code: "+49",
        api_token: this.user.api_token,
        oldEmail: this.user.email,
      })
        .then((res) => {
          this.showMsg({
            type: "success",
            text: this.$t("profileUpdated"),
            close: false,
          });
          this.editProfile();
        })
        .catch((err) => {
          this.showMsg({
            type: "error",
            text: this.$t("profileUpdateError"),
            close: false,
          });
        });
    },
    openModal(item) {
      $("#addressModal").modal("show");
      if (this.adding != true) {
        this.editItem = item ? item : "";
      } else {
        this.editItem = "";
      }
    },
    closeModal() {
      $("#addressModal").modal("hide");
      this.defaultAddress = false;
    },
    addressSubmit(item) {
      this.createAddress({
        ...item,
        api_token: this.user.api_token,
      })
        .then((res) => {
          this.showMsg({
            type: "success",
            text: this.$t("addressAdded"),
            close: false,
          });
          this.closeModal();
        })
        .catch((err) => {
          this.showMsg({
            type: "error",
            text: this.$t("addressAddedError"),
            close: false,
          });
        });
    },
    addressUpdate(item) {
      // console.log("🚀 ~ file: ProfileInfo.vue ~ line 262 ~ addressUpdate ~ item", item)
      this.updateAddress({
        ...item,
        api_token: this.user.api_token,
      })
        .then((res) => {
          this.showMsg({
            type: "success",
            text: this.$t("addressupdate"),
            close: false,
          });
          this.closeModal();
        })
        .catch((err) => {
          this.showMsg({
            type: "error",
            text: this.$t("addressUpdateError"),
            close: false,
          });
        });
    },
    addressDelete(item) {
      this.deleteAddress({
        api_token: this.user.api_token,
        address_id: item.id,
      })
        .then((res) => {
          this.showMsg({
            type: "success",
            text: this.$t("addressDeleted"),
            close: false,
          });
          this.closeModal();
        })
        .catch((err) => {
          this.showMsg({
            type: "error",
            text: this.$t("addressDeleteError"),
            close: false,
          });
        });
    },
    changeFile() {
      // console.log('asdasd')
      if (document.getElementById("file-id").files[0]) {
        this.fileName = document.getElementById("file-id").files[0].name;
      } else console.log("nothing");
    },
    goVerify() {
      let token = this.user.api_token;
      let identifier = this.user.email ? this.user.email : this.user.phone;
      this.userLogout(token)
        .then((res) => {
          this.$router.push({
            name: "VerifyAccount",
            params: {
              isEmail: this.user.email ? true : false,
              identifier,
              token,
            },
          });
        })
        .catch((err) => console.log(err));
    },
    defaultAddressAdd(item) {
      this.geolocation = {};
      this.setDefaultAddress({
        postal_code: item.postal_code,
        api_token: cookies.get("user_token"),
      })
        .then((res) => {
          console.log(res);
          cookies.set("defaultAddressId", item.id);
          this.showMsg({
            text: this.$t("DefaultAddressUpdated"),
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          this.showMsg({
            text: this.$t("DefaultAddressUpdateError"),
            type: "error",
          });
        });
    },
    validatePhone(col) {
      if (!empty(this.formData.phone)) {
        col.loading = true;
        this.$forceUpdate();
        this.checkPhone(this.formData.phone)
          .then((res) => {
            if (res.data.data == true) {
              col.valid = this.$t("VaildNumber");
              this.$forceUpdate();
            } else {
              $("#phone").select();
              col.error = this.$t("notVaildNumber");
              col.valid = null;
              this.$forceUpdate();
            }
          })
          .catch((err) => {
            $("#phone").select();
            col.error = this.$t("notVaildNumber");
            col.valid = null;
            this.$forceUpdate();
          });
      } else this.$forceUpdate();
    },
    checkDefaultAddress(add) {
      if (
        add.longitude &&
        add.longitude == this.user.longitude &&
        add.lattitude &&
        add.lattitude == this.user.latitude
      ) {
        return true;
      } else return false;
    },
  },
  computed: {
    ...mapGetters(["user", "userAddresses", "width", "loading", "isVerified"]),
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "";
    },
    ar() {
      return this.$i18n.locale == "ar";
    },
    formFields() {
      return [
        {
          cols: [
            {
              label: this.$t("FullName"),
              name: "name",
              id: "name",
              disabled: this.editFields,
            },
            {
              label: this.$t("col_email"),
              name: "email",
              id: "email",
              disabled: this.editFields,
            },
          ],
        },
        {
          cols: [
            {
              label: this.$t("col_phone"),
              name: "phone",
              id: "phone",
              disabled: this.editFields,
              mask: "49###############",
            },
            {
              label: this.$t("avatar"),
              name: "image",
              id: "image",
              disabled: this.editFields,
              type: "file",
              id: "file-id",
              class: "file-field",
            },
          ],
        },
        {
          cols: [
            // { label: this.$t('country'), name: 'country', disabled: this.editFields},
            // { label: this.$t('state'), name: 'state', disabled: this.editFields},
          ],
        },
      ];
    },
    addressFields() {
      return [
        {
          cols: [
            {
              label: this.$t("col_postalcode"),
              name: "postal_code",
              required: "required",
            },
            { label: this.$t("col_houseNumber"), name: "apartment_no" },
          ],
        },
        {
          cols: [
            { label: this.$t("col_street"), name: "street_name" },
            { label: this.$t("user_address"), name: "address" },
          ],
        },
        {
          cols: [
            // { label: this.$t('country'), name: 'country', disabled: this.editFields},
            // { label: this.$t('state'), name: 'state', disabled: this.editFields},
          ],
        },
      ];
    },
  },
};
</script>