<template>
  <div class="container">
    <div class="row mt-3 pb-2" :class="width != 'sm' ? 'mx-9' : 'mx-0'">
      <div class="col-12 px-0">
        <div class="row px-0" :class="flex_ar" v-if="user">
          <div class="col-4 px-0 col-xl-2 d-flex justify-content-center">
            <img class="profile-avatar" :src="
              user.media && user.media[0] ? user.media[0].url : palceHolder
            " />
          </div>
          <div class="col-8 col-xl-6 px-0" :class="t_ar">
            <div class="modal-header-custom">{{ user.name || "???" }}</div>
            <div class="table-headers text-underline"> {{ user.email || "???" }} </div>
          </div>
          <div class="col-12 col-xl-4 px-0 my-4 my-xl-0 align-self-center text-center">
            <button class="btn-outline-pri my-2 mx-0 my-md-0" :class="width == 'sm' ? 'w-100' : ''" @click="logout">
              <i class="fa fa-sign-out"></i> {{ $t("logout") }} </button>
            <!--<button class="btn-pri radius-1 my-2 my-md-0" :class="width == 'sm' ? 'w-100' : ''" @click="editProfile">
              <i class="fa fa-pencil"></i>  
              {{$t('editProfile')}}
            </button> -->
          </div>
        </div>
        <NoPlaces v-else />
      </div>
    </div>
    <div class="profile-tabs row my-2">
      <div class="col-12 px-0">
        <nav class="navbar nav-sec navbar-expand-lg">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navtabs"
            aria-controls="navtabs" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fa fa-bars"></i>
          </button>
          <span v-if="width == 'sm'" :class="f_ar">{{ tabsData.find((el) => el.id == openedTab).name }}</span>
          <div class="collapse navbar-collapse" id="navtabs">
            <ul class="navbar-nav tabs-2 w-100 justify-content-center" style="background-color: unset"
              :class="width != 'sm' ? flex_ar : ''">
              <li v-for="tab in tabsData" :key="tab.id" class="
                  nav-item
                  tabs-link
                  list-sub
                  py-1 py-lg-0
                  px-1 px-lg-3 px-xl-4
                " :class="openedTab == tab.id ? 'active' : ''" @click="changeTab(tab.id)"> {{ tab.name }} </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <router-view :editFields="editFields" :editProfile="editProfile"></router-view>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import cookies from "vue-cookies";
import NoPlaces from "@/components/misc/NoPlaces.vue";

export default {
  components: {
    NoPlaces,
  },
  mounted() {
    let routeName = this.tabsData.find((el) => el.route == this.$route.name);
    if (routeName.id != this.openedTab) {
      // console.log("🚀 ~ file: ProfileLayout.vue ~ line 67 ~ mounted ~ this.openedTab", this.openedTab)
      // this.changeTab(routeName ? routeName.id : 'info')
      this.openedTab = routeName.id || "info";
    }
  },
  data() {
    return {
      openedTab: "info",
      editFields: true,

      palceHolder: require("../assets/images/miscellaneous/user-filler.png"),
    };
  },
  watch: {
    $route: function (newRoute, oldRoute) {
      let routeName = this.tabsData.find((el) => el.route == this.$route.name);
      if (routeName.id != this.openedTab) {
        // console.log("🚀 ~ file: ProfileLayout.vue ~ line 91 ~ this.openedTab", this.openedTab)
        this.changeTab(routeName ? routeName.id : "info");
      }
    },
  },
  computed: {
    ...mapGetters(["width", "user"]),
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "";
    },
    tabsData() {
      return [
        { id: "info", name: this.$t("info"), route: "ProfileInfo" },
        // { id: 'settings', name: this.$t('Settings'), route: 'ProfileSettings'  },
        { id: "orders", name: this.$t("myOrders"), route: "OrderHistory" },
        {
          id: "appointments",
          name: this.$t("Reservations"),
          route: "ProfileAppintments",
        },
        // { id: 'QR', name: this.$t('QrCode'), route: 'ProfileQR'   },
        // { id: 'payment', name: this.$t('PaymentMethods'), route: 'ProfileMethods'  },
        { id: "favs", name: this.$t("Favourites"), route: "ProfileFavourites" },
        { id: "business", name: this.$t("business"), route: "ProfileBusiness" },
        { id: "products", name: this.$t("menu"), route: "ProfileProducts" },
      ];
    },
  },
  methods: {
    ...mapActions(["userLogout", "getLoggedIn", "retrieveUser"]),
    ...mapMutations(["showMsg"]),
    logout() {
      // console.log(cookies.get('user_token'))
      this.userLogout({
        api_token: cookies.get("user_token"),
      })
        .then((res) => {
          this.getLoggedIn();
          this.$router.push({
            name: "Home",
          });
          this.showMsg({
            type: "success",
            text: $t("logoutSuccessful"),
            close: false,
          });
        })
        .catch((err) => console.log(err));
    },
    changeTab(id) {
      this.openedTab = id;
      let routeName = this.tabsData.find((el) => el.id == id).route;
      if (id != "info") {
        this.editFields = false;
      }
      this.$router.push({
        name: routeName,
        params: {
          TName: id,
        },
      });
    },
    editProfile() {
      if (this.openedTab != "info") {
        this.changeTab("info");
      }
      this.editFields = !this.editFields;
    },
  },
};
</script>